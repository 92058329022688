import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Customers from '../components/Customers';
import Section, { ColumnsSection, SectionColumn } from '../components/Section';
import LinkButton from '../components/LinkButton';
import { MainTitle, SectionTitle }  from '../components/Titles';
import Activity from '../components/Activity';
import MainImageSection from '../components/MainImageSection';

import { colors } from '../shared/styles';

const Column = styled(SectionColumn)`
  font-size : 2.1rem;
  text-align : justify;
  line-height : 4rem;

  &:first-child {
    margin-left : 0;
  }

  &:last-child {
    margin-right : 0;
  }
`;

const PortraitSection = styled(ColumnsSection)`
  margin-top : 12rem;
  margin-bottom : 12rem;
`;

const Portrait = styled.img`
  width : 33%;

  @media screen and (max-width: 425px) {
    width : 100%;
  }
`;

const DoubleColumn = styled(Column)`
  max-width : 66%;

  @media screen and (max-width: 425px) {
    max-width : 100%;
    width : 100%;
  }
`

const CitationSource = styled.div`
  font-family : TellMeMore;
  text-align : right;
  margin-top : 4rem;
  font-size : 3rem;
`;

const ContinousColumnSection = styled(Section)`
  font-size : 2.1rem;
  text-align : justify;
  columns : 3;
  column-gap : 6rem;
  line-height : 4rem;
  margin-bottom : 10rem;
  margin-top : 6rem;

  @media screen and (max-width: 425px) {
    columns : auto;
    column-gap : 6rem;
  }
`;

const DescriptionParagraph = styled.p`
  font-size : 2.1rem;
  margin-top : 0;
`;

const Page = () => (
  <Layout>
    <SEO title="Presentation" />
    <MainImageSection image={require('../images/presentation_image.jpg')}>
      <MainTitle
        strings={[
          "Nous disposons d'un important écosystème de partenaires",
          "en France comme à l'international"
        ]}
        />
    </MainImageSection>
    <SectionTitle>
      Qui sommes nous&nbsp;?
    </SectionTitle>
    <ColumnsSection>
      <Column>
        Fondée par Delphine Cohen en 2019, Tell Me More s’appuie sur une <strong>équipe pluridisciplinaire uniquement composée de seniors</strong>.
      </Column>
      <Column>
        L’agence dispose ainsi d’un important écosystème d’experts et de partenaires en France et à l’international permettant de monter une <strong>équipe sur-mesure en fonction des problématiques clients</strong>.
      </Column>
      <Column>
        Ethnologues, facilitateurs, experts en innovation et expérience client, graphistes, ergonomes, UX designers, CTO, cameraman, monteurs, recruteurs, planneurs stratégiques sont autant de ressources mobilisables rapidement sur vos projets.
      </Column>
    </ColumnsSection>
    <PortraitSection>
      <Portrait src={require('../images/delphine.jpg')} />
      <DoubleColumn>
        <em>« Sociologue de formation et après 10 ans passés en institut puis en cabinet de conseil j’ai fondé en 2019 ma propre agence. Mon souhait était de sortir des approches qualitatives classiques en proposant des méthodologies plus agiles, plus digitales, impliquant à la fois collaborateurs et clients finaux pour créer du lien, co-construire et établir un vrai dialogue entre la marque et ses clients.
        Après une formation en Design Thinking et en Innovation Games j’ai donc créé Tell Me More, qui met l’immersion et la connaissance fine des clients au service de l’action et de la prise de décision »</em>
        <CitationSource>
          Delphine Cohen, CEO, Founder
        </CitationSource>
      </DoubleColumn>
    </PortraitSection>
    <SectionTitle>
      Pourquoi Tell Me More&nbsp;?
    </SectionTitle>
    <ContinousColumnSection>
      <DescriptionParagraph>
        <strong>La mission de Tell Me More est de redonner du sens à l’action en reconnectant la marque avec ses clients.</strong>
      </DescriptionParagraph>
      <DescriptionParagraph>
        Pour cela, Tell Me More s’engage à sortir des processus d’études classiques pour créer un dialogue continu avec vos clients.
      </DescriptionParagraph>
      <DescriptionParagraph>
        <strong>Immersion, co-construction, mobilisation de l’intelligence collective,</strong> sont les engagements pris par Tell Me More pour <strong>aider les entreprises à développer de nouveaux produits ou services porteurs de sens et en lien avec les attentes clients.</strong>
      </DescriptionParagraph>

      <DescriptionParagraph>
        Ces approches innovantes combinant la rencontre physique et la discussion online mettent particulièrement l’accent sur la phase d’immersion et de compréhension des cibles pour :
      </DescriptionParagraph>
      -	<strong>Créer un véritable dialogue avec chaque individu</strong> et mieux comprendre leur rapport aux marques et à la consommation<br/>
      -	<strong>Intégrer un regard sociologique</strong> et une prise de recul sur les résultats en intégrant les grandes tendances sociétales<br/>
      -	<strong>Confronter la culture entreprise et la culture client</strong><br/>
      -	<strong>Aider les marques à redéfinir leur « brand purpose »</strong>, leur mission, leurs valeurs dans un contexte toujours plus contraint et mouvant<br/>
      -	<strong>Redonner du sens à l’action</strong>
    </ContinousColumnSection>
  </Layout>
)

export default Page
